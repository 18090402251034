<template>
  <v-dialog v-model="dialog" @click:outside="close" persistent max-width="800px">
    <v-card class="dialog-top-color">
      <v-app-bar flat color="rgba(0, 0, 0, 0)">
        <v-toolbar-title>
          {{ $t('externalData.externalDataUpdateDialogTitle') }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon color="error">
            mdi-close-box
          </v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-row class="mb-3">
          <v-col>
            {{ $t('externalData.externalDataUpdateDialogDescription') }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field v-model="selectedDocument.name" :label="$t('label.name')" solo :hint="$t('label.name')"
              persistent-hint>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field readonly solo :value="$options.filters.BlockchainTypeFormatter(selectedDocument.exchange)"
              persistent-hint :hint="$t('label.exchange')">
              <template v-slot:prepend-inner>
                <v-avatar size="25" class="mx-1">
                  <v-img :src="require(`../../assets/blockchain/${selectedDocument.exchange}.svg`)" contain>
                  </v-img>
                </v-avatar>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-alert type="info" dense>
          {{ $t('externalData.externalDataUpdateDialogInfo') }}
        </v-alert>
        <v-alert v-if="selectedDocument.documentStatusType == 'ERROR'" type="error" dense icon="mdi-alert-circle">
          {{ selectedDocument.errorType | ExternalDataErrorTypeFormatter }} {{ selectedDocument.errorHint &&
            selectedDocument.errorType != 'MISSING_PREVIOUS_TRANSACTIONS' && selectedDocument.errorType !=
            'CRYPTOCOM_FILES_MISSING' && selectedDocument.errorType !=
            'MANUAL_ERROR' ?
            $t('externalData.atLine') + selectedDocument.errorHint : null }}
        </v-alert>
        <v-alert v-if="selectedDocument.documentStatusType == 'WARNING'" type="warning" dense icon="mdi-alert">
          {{ $t('externalData.warningMissingData') + selectedDocument.errorHint + $t('externalData.warningMissingData2') }}
        </v-alert>
        <upload-files-component :update="true"></upload-files-component>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="updateData" color="accent" class="white--text ma-3" :loading="loading">
          {{ $t('externalData.externalDataUpdateDialogButton') }}</v-btn>
        <v-spacer v-if="$vuetify.breakpoint.xs" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UploadFilesComponent from '../misc/UploadFilesComponent.vue';
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex';
export default {
  name: 'update-external-data-dialog',
  components: { UploadFilesComponent },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loading: false,
      nameChanged: false,
      file: null,
    }
  },
  methods: {
    ...mapActions("alert", ["createAlert"]),
    ...mapActions("externalSource", ["updateMultipleExternalData"]),
    close() {
      this.$emit('update:dialog', false)
    },
    updateData() {
      this.loading = true
      let filesToUpload = []
      for (const file of this.files) {
        filesToUpload.push(file)
      }
      const payload = {
        name: this.selectedDocument.name,
        documentId: this.selectedDocument.id,
        files: filesToUpload
      }
      this.updateMultipleExternalData(payload).then(() => {
        this.createAlert({ message: this.$t('message.updateSourceSuccess'), type: "success" })
        this.files = []
        this.close();
        this.$emit("sourceUpdated", true);
      }).catch((err) => {
        if (err.response.data && err.response.data.message) {
          if (err.response.data.message == 'EXTERNAL_DATA_NOT_SUPPORTED') {
            this.createAlert({ message: this.$t('message.externalDataNotSupportedError'), type: "error" })
          } else if (err.response.data.message == 'EXTERNAL_DATA_NOT_UPLOADED') {
            this.createAlert({ message: this.$t('message.externalDataNotUploadedError'), type: "error" })
          } else if (err.response.data.message == 'EXTERNAL_DATA_NOT_PRESENT') {
            this.createAlert({ message: this.$t('message.externalDataNotPresentError'), type: "error" })
          } else if (err.response.data.message == 'EXTERNAL_DATA_NOT_CHANGED') {
            this.createAlert({ message: this.$t('message.externalDataNotUpdatedError'), type: "error" })
          } else {
            this.createAlert({ message: this.$t('message.genericError'), type: "error" })
          }
        } else {
          this.createAlert({ message: this.$t('message.genericError'), type: "error" })
        }
      }).finally(() => {
        this.loading = false
      });
    }
  },
  computed: {
    ...mapFields("externalSource", ["selectedDocument"]),
    ...mapFields("externalSource", ["files"]),
    getAlertType() {
      if (this.selectedDocument.documentStatusType == 'WARNING') {
        return 'warning';
      } else if (this.selectedDocument.documentStatusType == 'ERROR') {
        return 'error';
      } else {
        return 'info';
      }
    },
    getExchange() {
      if (this.exchange != 'GENERIC') {
        return this.$options.filters.BlockchainTypeFormatter(this.selectedDocument.exchange);
      } else {
        return this.$t('label.genericSource');
      }
    },
  },
}
</script>

<style></style>