<template>
    <v-tooltip top>
        <template v-slot:activator="{ on }">
            <div justify="center">
                <v-icon @click="downloadFile()" v-on="on">mdi-cloud-download</v-icon><br>
                <span class="caption file-size" v-if="fileSize">({{fileSize | fileSize}})</span>
            </div>
        </template>
        <span>{{ $t('message.downloadFile') }}</span>
    </v-tooltip>
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/store/utils/api';

export default {
    name:'FileDownload',
    props: {
        fileId: {
            type: String,
            required: true
        },
        fileName: {
            type: String,
            required: true
        },
        fileMimeType: {
            type: String,
            required: true
        },
        fileSize: {
            type: Number,
            required: false
        },
    },
    data(){
        return {
            
        }
    },
    computed: {
    },
    methods: {
        ...mapActions("alert", ["createAlert"]),
        downloadFile(){
            api.get('/file/' + this.fileId, {responseType: 'blob'}).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.fileName);
                document.body.appendChild(link);
                link.click();
            }).catch((err) => {
                // Convert blob to text
                if (err.response.data && err.response.data.type == 'application/json') {
                    err.response.data.text().then((text) => {
                        let data = JSON.parse(text);
                        if (data.message == 'FILE_NOT_PRESENT') {
                            this.createAlert({ message: this.$t('message.fileNotPresentError'), type: "error" })
                        }
                        if (data.message == 'WRONG_PRICING_PLAN') {
                            this.createAlert({ message: this.$t('message.fileDownloadPricingError'), type: "error" })
                        }
                    })
                } else {
                    this.createAlert({ message: this.$t('message.genericError'), type: "error" })
                }
            })
        }
    },
    
    mounted() {
        
    },
    filters: {
        fileSize: function (bytes) {
            switch (true) {
                case bytes>=100000:
                    return (bytes / Math.pow(10, 6)).toFixed(2) + " MB";
                default:
                    return (bytes / Math.pow(10, 3)).toFixed(2) + " kB";
            }
        }
    }
}
</script>

<style scoped>
    .file-size {
        white-space: nowrap;
    }
</style>