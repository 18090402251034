<template>
  <div>
    <v-btn icon @click="deleteAddressDialog = true">
      <v-icon>
        mdi-delete
      </v-icon>
    </v-btn>
    <confirm-dialog :title="$t('address.deleteAddressTitle')" :message="$t('address.deleteAddressMessage')"
      :showDialog="deleteAddressDialog" :loading="loading" @confirmed="onConfirmed" :isError="true"
      :confirmButtonText="$t('address.deleteAddressConfirmButton')">
      <template v-slot:body>
          <span >{{ $t('address.deleteAddressMessage') }}</span>
          <v-alert class="mt-3" dense icon="mdi-alert" prominent type="error" v-if="isExchange(address)">
              {{ $t('address.addressDeleteDialogInfo') }}
          </v-alert>
      </template>
    </confirm-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ConfirmDialog from '../dialog/ConfirmDialog.vue';
export default {
  name: 'delete-address-button',
  props: {
    address: {
      type: String,
      required: true
    },
    showAlert: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    ConfirmDialog
  },
  data() {
    return {
      loading: false,
      deleteAddressDialog: false,
    }
  },
  computed: {
  },
  methods: {
    ...mapActions("selectedUserAddress", ["deleteUserAddress"]),
    ...mapActions("alert", ["createAlert"]),
    onConfirmed(confirmed) {
      if (confirmed) {
        this.deleteAddress()
      } else {
        this.deleteAddressDialog = false;
      }
    },
    deleteAddress() {
      this.loading = true;
      this.deleteUserAddress(this.address).then(() => {
        this.createAlert({ message: this.$t('message.deleteUserAddressSuccess'), type: "success" })
        this.$emit("addressDeleted", true);
      }).catch((err) => {
        if (err.response.data && err.response.data.message) {
          if (err.response.data.message == 'ADDRESS_NOT_PRESENT') {
            this.createAlert({ message: this.$t('message.deleteUserAddressAddressNotPresentError'), type: "error" })
          }
        } else {
          this.createAlert({ message: this.$t('message.genericError'), type: "error" })
        }
      }).finally(() => {
        this.deleteAddressDialog = false;
        this.loading = false
      });
    },
    isExchange(address){
      return this.$SUPPORTED_EXCHANGES.some((exchange) => {
        if (address.toLowerCase().startsWith(exchange.toLowerCase())){
          return true;
        }
      })
    }
  }

}
</script>

<style>
</style>