<template>
  <v-container fluid>
    <v-row no-gutters v-if="$vuetify.breakpoint.smAndUp">
      <v-col cols="auto">
        <v-row no-gutters>
          <v-col>
            <h1>{{ $t('connection.connectionManagementTitle') }}</h1>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            {{ $t('connection.connectionManagementDescription') }}
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <v-row no-gutters>
          <!--
          <v-col>
            <sync-addresses-button :addresses="getAddresses" :text="$t('address.syncAllAddressesButton')"
              @successfulSync="fetchData"></sync-addresses-button>
          </v-col>
          -->
          <v-col>
            <router-button :text="$t('connection.addConnectionButton')" redirect="/add-connection"></router-button>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <template v-if="$vuetify.breakpoint.xs">
      <v-row no-gutters>
        <v-col :align="'center'" :justify="'center'">
          <h1>{{ $t('connection.connectionManagementTitle') }}</h1>
        </v-col>
      </v-row>
      <v-row no-gutters  class="mb-3">
        <v-col :align="'center'" :justify="'center'">
          {{ $t('connection.connectionManagementDescription') }}
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-1">
      <v-spacer />
      <v-col>
        <router-button :text="$t('connection.addConnectionButton')" redirect="/add-connection"></router-button>
      </v-col>
      <v-spacer />
    </v-row>
    <!--
    <v-row no-gutters>
      <v-spacer />
      <v-col>
        <sync-addresses-button :addresses="getAddresses" :text="$t('address.syncAllAddressesButton')"
              @successfulSync="fetchData"></sync-addresses-button>
      </v-col>
      <v-spacer />
    </v-row>
    -->
    </template>
    <v-row>
      <v-col>
        <paged-data-table :namespace="userAddressesNamespace" itemEndpoint="/userAddress/search"
          :itemsHeaders="userAddressesHeaders" itemKey="id" :refresh="refresh" @dataFetched="onDataFetched" sortBy="name"
          :sortDesc="false">
          <template v-slot:[`item.userAddressType`]="{ item }">
            {{ item.userAddressType | UserAddressTypeFormatter }}
          </template>
          <template v-slot:[`item.address`]="{ item }">
            <v-row :align="'center'">
              {{ item.address.address | AddressFormatter }} <copy-address-button :address="item.address.address">
              </copy-address-button>
            </v-row>
          </template>
          <template v-slot:[`item.tag`]="{ item }">
            <template v-if="item.tag">
              <tag-chip :tag="item.tag"></tag-chip>
            </template>
            <template v-else>
              -
            </template>
          </template>
          <template v-slot:[`item.address.updated`]="{ item }">
            <address-status-component :address="item.address" @successfulSync="fetchData">
            </address-status-component>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-row>
              <update-address-button :userAddress="item" @addressUpdated="fetchData"></update-address-button>
              <delete-address-button :address="item.address.address" @addressDeleted="fetchData"></delete-address-button>
            </v-row>
          </template>
        </paged-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer v-if="$vuetify.breakpoint.xs"/>
      <v-col cols="auto">
        <h2>{{ $t('externalData.externalDataTitle') }}</h2>
      </v-col>
      <v-spacer v-if="$vuetify.breakpoint.xs"/>
    </v-row>
    <v-row no-gutters>
      <v-spacer v-if="$vuetify.breakpoint.xs"/>
      <v-col cols="auto">
        {{ $t('externalData.externalDataDescription') }}
      </v-col>
      <v-spacer v-if="$vuetify.breakpoint.xs"/>
    </v-row>
    <v-row>
      <v-col>
        <external-data-table :refresh="refresh" @dataFetched="refresh = false" @dataDeleted="fetchData"
          @dataUpdated="fetchData"></external-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { mapFields } from 'vuex-map-fields'
//import SyncAddressesButton from '../../components/button/SyncAddressesButton.vue'
import RouterButton from "../../components/button/RouterButton.vue";
import TagChip from '../../components/chip/TagChip.vue';
import AddressStatusComponent from '../../components/misc/AddressStatusComponent.vue';
import DeleteAddressButton from '../../components/button/DeleteAddressButton.vue';
import UpdateAddressButton from '../../components/button/UpdateAddressButton.vue';
import PagedDataTable from '../../components/table/PagedDataTable.vue'
import CopyAddressButton from '../../components/button/CopyAddressButton.vue';
import ExternalDataTable from "../../components/table/ExternalDataTable.vue";
export default {
  name: 'connection-management-page',
  components: { 
    // SyncAddressesButton, 
    PagedDataTable, AddressStatusComponent, TagChip, RouterButton, DeleteAddressButton, UpdateAddressButton, CopyAddressButton, ExternalDataTable },
  data() {
    return {
      refresh: false,
      userAddressesNamespace: "user-addresses",
      userAddressesHeaders: [
        { text: this.$t('label.name'), value: "name" },
        { text: this.$t('label.address'), value: "address", sortable: false },
        { text: this.$t('label.tag'), value: "tag", sortable: false },
        { text: this.$t('label.status'), value: "address.updated", sortable: false },
        { value: "actions", sortable: false },
      ],
    }
  },
  computed: {
    ...mapFields("userAddresses", ["addresses"]),
    getAddresses() {
      return this.addresses.map(address => address.address.address)
    }
  },
  methods: {
    ...mapActions("userAddresses", ["fetchUserAddresses"]),
    fetchData(success) {
      if (success) {
        this.refresh = true;
      }
    },
    onDataFetched(success) {
      if (success) {
        this.refresh = false;
      }
    }
  },
  created() {
    this.fetchUserAddresses()
  }
}
</script>

<style></style>