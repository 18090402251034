<template>
    <div>
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <div justify="center">
                    <v-icon @click="deleteDataDialog = true" v-on="on">
                        mdi-delete
                    </v-icon>
                </div>
            </template>
            <span>{{ $t('message.deleteFile') }}</span>
        </v-tooltip>
        <confirm-dialog :title="$t('externalData.externalDataDeleteDialogTitle')" :message="$t('externalData.externalDataDeleteDialogDescription')"
            :showDialog="deleteDataDialog" :loading="loading" @confirmed="onConfirmed" :isError="true"
            :confirmButtonText="$t('externalData.externalDataDeleteDialogButton')">
            <!-- Use the body slot to add alert for user -->
            <template v-slot:body>
                <span >{{ $t('externalData.externalDataDeleteDialogDescription') }}</span>
                <v-alert class="mt-3" dense icon="mdi-alert" prominent type="error">
                    {{ $t('externalData.externalDataDeleteDialogInfo') }}
                </v-alert>
            </template>
        </confirm-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import ConfirmDialog from '../dialog/ConfirmDialog.vue';
export default {
    name: 'delete-external-data-button',
    props: {
        documentId: {
            type: String,
            required: true
        }
    },
    components: {
        ConfirmDialog
    },
    data() {
        return {
            loading: false,
            deleteDataDialog: false,
        }
    },
    computed: {
    },
    methods: {
        ...mapActions("externalSource", ["deleteExternalData"]),
        ...mapActions("alert", ["createAlert"]),
        onConfirmed(confirmed) {
            if (confirmed) {
                this.deleteData()
            } else {
                this.deleteDataDialog = false;
            }
        },
        deleteData() {
            this.loading = true;
            this.deleteExternalData(this.documentId).then(() => {
                this.createAlert({ message: this.$t('message.deleteUserExternalDocumentSuccess'), type: "success" })
                this.$emit("dataDeleted", true);
            }).catch((err) => {
                if (err.response.data && err.response.data.message) {
                    if (err.response.data.message == 'EXTERNAL_DATA_NOT_PRESENT') {
                        this.createAlert({ message: this.$t('message.externalDataNotPresentError'), type: "error" })
                    }
                } else {
                    this.createAlert({ message: this.$t('message.genericError'), type: "error" })
                }
            }).finally(() => {
                this.deleteDataDialog = false;
                this.loading = false
            });
        }
    }

}
</script>

<style>

</style>