var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('paged-data-table',{attrs:{"namespace":_vm.namespace,"itemEndpoint":_vm.endpoint,"itemsHeaders":_vm.getHeaders,"itemKey":"id","sortBy":"created","sortDesc":true,"refresh":_vm.refresh},on:{"dataFetched":_vm.onDataFetched},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("DateFormatter")(item.created))+" ")]}},{key:"item.exchangeType",fn:function(ref){
var item = ref.item;
return [_c('blockchain-type-component',{attrs:{"blockchainType":item.exchangeType}})]}},{key:"item.transactionNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.transactionNumber ? item.transactionNumber : '-')+" ")]}},{key:"item.firstTransaction",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstTransaction ? _vm.$options.filters.DateFormatter(item.firstTransaction) : '-')+" ")]}},{key:"item.lastTransaction",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.lastTransaction ? _vm.$options.filters.DateFormatter(item.lastTransaction) : '-')+" ")]}},{key:"item.documentStatusType",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.getStatusColor(item.documentStatusType)}},on),[_vm._v(_vm._s(_vm.getStatusIcon(item.documentStatusType)))])]}}],null,true)},[(item.documentStatusType != 'ERROR')?_c('span',[_vm._v(_vm._s(_vm._f("DocumentStatusFormatter")(item.documentStatusType)))]):_c('span',[_vm._v(_vm._s(_vm._f("ExternalDataErrorTypeFormatter")(item.errorType)))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":'center',"justify":'center'}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","align":'center',"justify":'center'}},[_c('update-external-data-button',{attrs:{"document":item},on:{"dataUpdated":_vm.onDataUpdated}}),_c('delete-external-data-button',{staticClass:"mx-1",attrs:{"documentId":item.id},on:{"dataDeleted":_vm.onDataDeleted}}),(item.fileId != null)?[_c('file-download',{staticClass:"mx-1",attrs:{"fileId":item.fileId,"fileSize":null,"fileMimeType":item.fileMimeType,"fileName":item.fileName}})]:_vm._e()],2)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }