<template>
<div>
    <v-btn icon @click="openUpdateAddressDialog">
      <v-icon>
        mdi-pencil
      </v-icon>
    </v-btn>
    <update-address-dialog :dialog.sync="updateAddressDialog" @addressUpdated="onUpdated"></update-address-dialog>
  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields';
import UpdateAddressDialog from '../dialog/UpdateAddressDialog.vue';
const _ = require('lodash');
export default {
  name: 'update-address-button',
  props: {
    userAddress: {
      type: Object,
      required: true
    }
  },
  components: {
    UpdateAddressDialog
  },
  data() {
    return {
      updateAddressDialog: false,
    }
  },
  computed: {
    ...mapFields("selectedUserAddress", ["selectedUserAddress"]),
  },
  methods: {
    onUpdated(updated){
      if (updated){
        this.$emit("addressUpdated", true); // solo se ha funzionato
      }
      this.updateAddressDialog = false;
    },
    openUpdateAddressDialog(){
      this.updateAddressDialog = true;
      this.selectedUserAddress = _.cloneDeep(this.userAddress)
    }
  }

}
</script>

<style>
</style>