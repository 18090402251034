<template>
    <v-btn v-if="onlyIcon" icon @click="syncAddresses" :disabled="isButtonDisabled || disabled" :loading="loading">
      <v-icon>
        mdi-sync
      </v-icon>
    </v-btn>
    <v-btn v-else @click="syncAddresses" :disabled="isButtonDisabled || disabled" :loading="loading" color="primary"  text>
      {{ text }}
      <v-icon>
        mdi-sync
      </v-icon>
    </v-btn>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'sync-addresses-button',
  props: {
    addresses: {
      type: Array,
      required: true
    },
    text: {
      type: String,
      required: false
    },
    onlyIcon: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    isButtonDisabled() {
      if (this.addresses) {
        return this.addresses.length == 0
      }
      return true;
    }
  },
  methods: {
    ...mapActions("userAddresses", ["syncUserAddresses"]),
    ...mapActions("alert", ["createAlert"]),
    syncAddresses() {
      this.loading = true;
      let payload = { addresses: this.addresses }
      this.syncUserAddresses(payload).then(() => {
        this.createAlert({ message: this.$t('message.syncUserAddressesSuccess'), type: "success" })
        this.$emit("successfulSync", true);
      }).catch((err) => {
        if (err.response.data && err.response.data.message) {
          if (err.response.data.message == 'ADDRESS_NOT_PRESENT') {
            this.createAlert({ message: this.$t('message.syncUserAddressesAddressNotPresentError'), type: "error" })
          }
          if (err.response.data.message == 'ADDRESS_ALREADY_UPDATING') {
            this.createAlert({ message: this.$t('message.syncUserAddressesAddressAlreadyUpdatingError'), type: "error" })
          }
        } else {
          this.createAlert({ message: this.$t('message.genericError'), type: "error" })
        }
      }).finally(() => {
        this.loading = false
      });
    }
  }

}
</script>

<style>
</style>